import React, { useEffect, useId, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useOutsideClick } from "./hooks/use-outside-click";
import Mech6 from "../assets/book/Mech6.jpg";
import Mech5 from "../assets/book/Mech5.jpg";
import Mech4 from "../assets/book/Mech4.jpg";
import Mech3 from "../assets/book/Mech3.jpg";
import Mech2 from "../assets/book/Mech2.jpg";
import Mech1 from "../assets/book/Mech1.jpg";
import EEE2 from "../assets/book/EEE2.jpg";
import EEE3 from "../assets/book/EEE3.jpg";
import EEE4 from "../assets/book/EEE4.jpg";
import EEE1 from "../assets/book/EEE1.jpg";
import EEE6 from "../assets/book/EEE6.jpg";
import EEE5 from "../assets/book/EEE5.jpg";
import Civil3 from "../assets/book/Civil3.jpg";
import Civil4 from "../assets/book/Civil4.jpg";
import Civil5 from "../assets/book/Civil5.jpg";
import Civil6 from "../assets/book/Civil6.jpg";
import Civil7 from "../assets/book/Civil7.jpg";
import Civil2 from "../assets/book/Civil2.jpg";
import Civil1 from "../assets/book/Civil1.jpg";
import B4 from "../assets/book/B4.jpg";
import B1 from "../assets/book/B1.jpg";
import B5 from "../assets/book/B5.jpg";
import B2 from "../assets/book/B2.jpg";
import B6 from "../assets/book/B6.jpg";
import B3 from "../assets/book/B3.jpg";
import B7 from "../assets/book/B7.jpg";
import Agri5 from "../assets/book/Agri5.jpg";
import Agr1 from "../assets/book/Agr1.jpg";
import Agri2 from "../assets/book/Agri2.jpg";
import Agri4 from "../assets/book/Agri4.jpg";
import Agri6 from "../assets/book/Agri6.jpg";
import Agri from "../assets/book/Agri.jpg";
import Agri3 from "../assets/book/Agri3.jpg";
import Chemis4 from "../assets/book/Chemis4.jpg";
import Chemis3 from "../assets/book/Chemis3.jpg";
import Chemis2 from "../assets/book/Chemis2.jpg";
import Chemis1 from "../assets/book/Chemis1.jpg";
import Phy5 from "../assets/book/Phy5.jpg";
import Phy4 from "../assets/book/Phy4.jpg";
import Phy3 from "../assets/book/Phy3.jpg";
import Phy2 from "../assets/book/Phy2.jpg";
import Phy1 from "../assets/book/Phy1.jpg";
import Eng3 from "../assets/book/Eng3.jpg";
import Eng2 from "../assets/book/Eng2.jpg";
import Eng1 from "../assets/book/Eng1.jpg";
import ECE10 from "../assets/book/ECE10.jpg";
import ECE9 from "../assets/book/ECE9.jpg";
import ECE8 from "../assets/book/ECE8.jpg";
import ECE7 from "../assets/book/ECE7.jpg";
import ECE6 from "../assets/book/ECE6.jpg";
import ECE5 from "../assets/book/ECE5.jpg";
import ECE4 from "../assets/book/ECE4.jpg";
import ECE3 from "../assets/book/ECE3.jpg";
import ECE2 from "../assets/book/ECE2.jpg";
import ECE1 from "../assets/book/ECE1.jpg";
import M5 from "../assets/book/M5.jpg";
import M4 from "../assets/book/M4.jpg";
import M3 from "../assets/book/M3.jpg";
import M2 from "../assets/book/M2.jpg";
import M1 from "../assets/book/M1.jpg";
import CSE10 from "../assets/book/CSE10.jpg";
import CSE1 from "../assets/book/CSE1.jpg";
import CSE9 from "../assets/book/CSE9.jpg";
import CSE8 from "../assets/book/CSE8.jpg";
import CSE7 from "../assets/book/CSE7.jpg";
import CSE6 from "../assets/book/CSE6.jpg";
import CSE5 from "../assets/book/CSE5.jpg";
import CSE4 from "../assets/book/CSE4.jpg";
import CSE3 from "../assets/book/CSE3.jpg";
import CSE2 from "../assets/book/CSE2.jpg";


const cards = [
  { title: "Mechanical Engineering Thermodynamics: Concepts, Applications, and Design", src: Mech6, year: 2023, content: () => <p>Author :  Vinay Chandra A, Patil Sundeep <br/> Year :  2023<br/>ISBN No :  978-93-6738-499-2<br/>Cost :  ₹550 </p>},
  { title: "Robust Control in Mechanical Engineering: Techniques for Dynamic Systems", src: Mech5, year: 2023, content: () => <p>Author :  Sandeep Kumar Vangala, Ch Rajendra Prasad<br/>Year :  2023<br/>ISBN No :  978-93-6738-758-0<br/>Cost :  ₹550 </p>},
  { title: "Computational Mechanics: Methods for Solving Engineering Problems", src: Mech4, year: 2023, content: () => <p>Author :  Srinivas Aluvala, Y Nagender<br/>Year :  2023<br/>ISBN No :  978-93-6738-928-7<br/>Cost :  ₹550 </p>},
  { title: "Energy Efficiency in Mechanical Systems: Concepts, Techniques, and Case Studies", src: Mech3, year: 2022, content: () => <p>Author :  N Suman Kumar, Srinivas D<br/>Year :  2022<br/>ISBN No :  978-93-6738-108-3<br/>Cost :  ₹550 </p>},
  { title: "Mechanical Systems Design: Integrating Theory and Practice", src: Mech2, year: 2022, content: () => <p>Author :  K S V A Satheesh Kavuri, Chakradhar Padamutham<br/>Year :  2022<br/>ISBN No :  978-93-6738-337-7<br/>Cost :  ₹550 </p>},
  { title: "Advanced Materials and Manufacturing Processes: Innovations and Applications", src: Mech1, year: 2022, content: () => <p>Author :  Vutukuru Mahesh, Pulla Sammaiah<br/>Year :  2022<br/>ISBN No :  978-93-6738-868-6<br/>Cost :  ₹550 </p>},
  { title: "Embedded Systems Design: Integrating Hardware and Software in Electronics", src: EEE2, year: 2023, content: () => <p>Author :  Dharmendra Deonath Yadeo, Sachidhananda Sen<br/>Year :  2023<br/>ISBN No :  978-93-6738-822-8<br/>Cost :  ₹550 </p>},
  { title: "High Voltage Engineering: Principles, Technologies, and Applications", src: EEE3, year: 2023, content: () => <p>Author :  Md Mujahid Irfan, Srinivas Aluvala<br/>Year :  2023<br/>ISBN No :  978-93-6738-081-9<br/>Cost :  ₹550 </p>},
  { title: "Electromagnetic Field Theory: Concepts and Computational Techniques", src: EEE4, year: 2022, content: () => <p>Author :  Thota Soujanya, Bhabani Prasad Mondal<br/>Year :  2022<br/>ISBN No :  978-93-6738-438-1<br/>Cost :  ₹550 </p>},
  { title: "Microelectronics and Nanoelectronics: Building the Future of Circuit Design", src: EEE1, year: 2022, content: () => <p>Author :  Boda Somraj, Nagamandla Ramya Sri<br/>Year :  2022<br/>ISBN No :  978-93-6738-581-4<br/>Cost :  ₹550 </p>},
  { title: "Advanced Control Systems in Power Electronics: Techniques and Applications", src: EEE6, year: 2022, content: () => <p>Author :  Md Mujahid Irfan, Vutukuru Mahesh<br/>Year :  2022<br/>ISBN No :  978-93-6738-464-0<br/>Cost :  ₹550 </p>},
  { title: "Smart Grids and Renewable Energy: Innovations in Electrical Power Systems", src: EEE5, year: 2022, content: () => <p>Author :  Chandan Kumar Shiva, Shriram Srinivasarangan Rangarajan<br/>Year :  2022<br/>ISBN No :  978-93-6738-303-2<br/>Cost :  ₹550 </p>},
  { title: "Transportation Systems Engineering: Optimization and Planning for the Future", src: Civil3, year: 2023, content: () => <p>Author :  Shriram Srinivasarangan Rangarajan, Sandeep Kumar Vangala<br/>Year :  2023<br/>ISBN No :  978-93-6738-288-2<br/>Cost :  ₹550 </p>},
  { title: "Seismic Design of Structures: Principles and Case Studies", src: Civil4, year: 2023, content: () => <p>Author :  D Raja Babu, Jalla Vamshi<br/>Year :  2023<br/>ISBN No :  978-93-6738-553-1<br/>Cost :  ₹550 </p>},
  { title: "Water Resources Engineering: Integrated Management and Planning", src: Civil5, year: 2022, content: () => <p>Author :  Gunta Bhupal Raj, D Raja Babu<br/>Year :  2022<br/>ISBN No :  978-93-6738-561-6<br/>Cost :  ₹550 </p>},
  { title: "Smart Materials in Civil Engineering: Innovations and Applications", src: Civil6, year: 2022, content: () => <p>Author :  Gurunadham Goli, A Rajeshwar Rao<br/>Year :  2022<br/>ISBN No :  978-93-6738-060-4<br/>Cost :  ₹550 </p>},
  { title: "Geotechnical Engineering in Practice: Foundations, Soils, and Earth Structures", src: Civil7, year: 2022, content: () => <p>Author :  Rajesh Kumar K, Nagamandla Ramya Sri<br/>Year :  2022<br/>ISBN No :  978-93-6738-037-6<br/>Cost :  ₹550 </p>},
  { title: "Sustainable Urban Infrastructure: Planning and Development Strategies", src: Civil2, year: 2022, content: () => <p>Author :  Nigitha D, Chandan Kumar Shiva<br/>Year :  2022<br/>ISBN No :  978-93-6738-907-2<br/>Cost :  ₹550 </p>},
  { title: "Advanced Structural Engineering: Design and Analysis Techniques", src: Civil1, year: 2022, content: () => <p>Author :  Murthi P, R Gobinath<br/>Year :  2022<br/>ISBN No :  978-93-6738-173-1<br/>Cost :  ₹550 </p>},
  { title: "Building Resilient Organizations: Strategies for Long-Term Success in Uncertain Times", src: B4, year: 2023, content: () => <p>Author :  Sumit Kumar Gupta, Indrasen Singh<br/>Year :  2023<br/>ISBN No :  978-93-6738-714-6<br/>Cost :  ₹550 </p>},
  { title: "Innovative Management Techniques: Driving Success Through Creative Leadership", src: B1, year: 2022, content: () => <p>Author :  Gaddam Satheesh Raju, Gurunadham Goli<br/>Year :  2022<br/>ISBN No :  978-93-6738-914-0<br/>Cost :  ₹550 </p>},
  { title: "Global Business Strategies: Competing in a Connected World", src: B5, year: 2022, content: () => <p>Author :  Ajayan J, Sandip Bhattacharya<br/>Year :  2022<br/>ISBN No :  978-93-6738-028-4<br/>Cost :  ₹550 </p>},
  { title: "Data-Driven Decision Making: Harnessing Analytics for Business Growth", src: B2, year: 2022, content: () => <p>Author :  Shyamala G, Rajesh Kumar K<br/>Year :  2022<br/>ISBN No :  978-93-6738-271-4<br/>Cost :  ₹550 </p>},
  { title: "Sustainable Business Practices: Integrating Profitability and Corporate Responsibility", src: B6, year: 2022, content: () => <p>Author :  Saarla Ramesh, Veerapaga Nagendram<br/>Year :  2022<br/>ISBN No :  978-93-6738-603-3<br/>Cost :  ₹550 </p>},
  { title: "Agile Management: Adapting to Change in a Dynamic Business Environment", src: B3, year: 2022, content: () => <p>Author :  Saarla Ramesh, Murthi P<br/>Year :  2022<br/>ISBN No :  978-93-6738-560-9<br/>Cost :  ₹550 </p>},
  { title: "Strategic Leadership in the Digital Age: Navigating Business Transformation", src: B7, year: 2022, content: () => <p>Author :  N Suman Kumar, Srinivas D<br/>Year :  2022<br/>ISBN No :  978-93-6738-401-5<br/>Cost :  ₹550 </p>},
  { title: "Smart Farming Systems Integrating IoT, AI, and Robotics in Modern Agriculture", src: Agri5, year: 2023, content: () => <p>Author :  Gurunadham Goli,Y Nagender<br/>Year :  2023<br/>ISBN No :  978-93-6738-086-4<br/>Cost :  ₹550 </p>},
  { title: "Irrigation Engineering Design, Implementation, and Water Resource Management", src: Agr1, year: 2023, content: () => <p>Author :  Atla Rangarani,Veerapaga Nagendram<br/>Year :  2023<br/>ISBN No :  978-93-6738-778-8<br/>Cost :  ₹550 </p>},
  { title: "Post-Harvest Technology and Management Enhancing Food Security through Engineering", src: Agri2, year: 2022, content: () => <p>Author :  Rathod Lalsingh,Muddasani Rajyalaxmi<br/>Year :  2022<br/>ISBN No :  978-93-6738-068-0<br/>Cost :  ₹550 </p>},
  { title: "Renewable Energy in Agriculture Harnessing Sustainable Power for Rural Development", src: Agri4, year: 2022, content: () => <p>Author :  Srinivas D,D.Ramesh<br/>Year :  2022<br/>ISBN No :  978-93-6738-057-4<br/>Cost :  ₹550 </p>},
  { title: "Soil and Water Engineering Concepts, Tools, and Techniques for Efficient Land Use", src: Agri6, year: 2022, content: () => <p>Author :  Srivani N,Patil Sundeep<br/>Year :  2022<br/>ISBN No :  978-93-6738-738-2<br/>Cost :  ₹550 </p>},
  { title: "Agricultural Machinery and Mechanization Principles, Practices, and Innovations", src: Agri, year: 2022, content: () => <p>Author :  Bhabani Prasad Mondal,Tithli Sadhu<br/>Year :  2022<br/>ISBN No :  978-93-6738-845-7<br/>Cost :  ₹550 </p>},
  { title: "Precision Agriculture Technologies and Applications in Sustainable Farming", src: Agri3, year: 2022, content: () => <p>Author :  Mohana Keerthi M,Nagamandla Ramya Sri<br/>Year :  2022<br/>ISBN No :  978-93-6738-686-6<br/>Cost :  ₹550 </p>},
  { title: "Inorganic Chemistry A Comprehensive Guide to Structures, Reactions, and Mechanisms", src: Chemis4, year: 2023, content: () => <p>Author :  S Naresh Kumar, Gaddam Satheesh Raju<br/>Year :  2023<br/>ISBN No :  978-93-6738-324-7<br/>Cost :  ₹550 </p>},
  { title: "Environmental Chemistry Principles, Practices, and Applications", src: Chemis3, year: 2023, content: () => <p>Author :  Pankaj Kumar, Sandeep Kumar Vangala<br/>Year :  2023<br/>ISBN No :  978-93-6738-753-5<br/>Cost :  ₹550 </p>},
  { title: "Applied Organic Chemistry Techniques and Innovations for Modern Research", src: Chemis2, year: 2022, content: () => <p>Author :  Sivakirshna Reddy, K Sudheer Kumar<br/>Year :  2022<br/>ISBN No :  978-93-6738-171-7<br/>Cost :  ₹550 </p>},
  { title: "Molecular Chemistry Foundations and Advanced Concepts", src: Chemis1, year: 2022, content: () => <p>Author :  Srivani N, Purushotham Endla<br/>Year :  2022<br/>ISBN No :  978-93-6738-277-6<br/>Cost :  ₹550 </p>},
  { title: "Modern Optics From Basic Principles to Advanced Applications", src: Phy5, year: 2023, content: () => <p>Author :  Bivas Bank,Bandi Mallesham<br/>Year :  2023<br/>ISBN No :  978-93-6738-906-5<br/>Cost :  ₹550 </p>},
  { title: "Thermodynamics and Statistical Physics Principles and Applications", src: Phy4, year: 2023, content: () => <p>Author :  Prashanth Kumar,Gurunadham Goli<br/>Year :  2023<br/>ISBN No :  978-93-6738-526-5<br/>Cost :  ₹550 </p>},
  { title: "Electromagnetism Theory, Practice, and Applications", src: Phy3, year: 2022, content: () => <p>Author :  Purushotham Endla,G Sunil Reddy<br/>Year :  2022<br/>ISBN No :  978-93-6738-866-2<br/>Cost :  ₹550 </p>},
  { title: "Classical Mechanics Concepts, Applications, and Problem-Solving", src: Phy2, year: 2022, content: () => <p>Author :  Raj Kumar Samudrala,Subhajit Das<br/>Year :  2022<br/>ISBN No :  978-93-6738-747-4<br/>Cost :  ₹550 </p>},
  { title: "Fundamentals of Quantum Mechanics A Comprehensive Introduction", src: Phy1, year: 2022, content: () => <p>Author :  Bandi Mallesham,Nagaraju Daasi<br/>Year :  2022<br/>ISBN No :  978-93-6738-142-7<br/>Cost :  ₹550 </p>},
  { title: "Critical Reading and Writing Skills for Academic Success", src: Eng3, year: 2023, content: () => <p>Author :  Patil Sundeep,B Sathyavani<br/>Year :  2023<br/>ISBN No :  978-93-6738-118-2<br/>Cost :  ₹550 </p>},
  { title: "Introduction to Linguistics Understanding Language Structure and Use", src: Eng2, year: 2022, content: () => <p>Author :  Patil Sundeep,Raja Shekar P V<br/>Year :  2022<br/>ISBN No :  978-93-6738-825-9<br/>Cost :  ₹550 </p>},
  { title: "Mastering English Grammar and Composition A Comprehensive Guide for Students", src: Eng1, year: 2022, content: () => <p>Author :  Patil Sundeep,Raja Shekar P V<br/>Year :  2022<br/>ISBN No :  978-93-6738-110-6<br/>Cost :  ₹550 </p>},
  { title: "Robust Control Systems in ECE: Theory, Design, and Applications", src: ECE10, year: 2023, content: () => <p>Author :  G. Vijaya, Eranki L N Kiran Kumar<br/>Year :  2023<br/>ISBN No :  978-93-6738-383-4<br/>Cost :  ₹550 </p>},
  { title: "Photonics and Optical Communications: Fundamentals and Advances", src: ECE9, year: 2022, content: () => <p>Author :  Syed Nageena Parveen, Sumit Kumar Gupta<br/>Year :  2022<br/>ISBN No :  978-93-6738-184-7<br/>Cost :  ₹550 </p>},
  { title: "Next-Generation Antenna Design: Theories, Challenges, and Innovations", src: ECE8, year: 2023, content: () => <p>Author :  Boda Somraj, Ram Raghotham Rao Deshmukh<br/>Year :  2023<br/>ISBN No :  978-93-6738-889-1<br/>Cost :  ₹550 </p>},
  { title: "Microwave Engineering: Concepts, Techniques, and Practical Applications", src: ECE7, year: 2023, content: () => <p>Author :  Sumit Kumar Gupta, Indrasen Singh<br/>Year :  2023<br/>ISBN No :  978-93-6738-474-9<br/>Cost :  ₹550 </p>},
  { title: "IoT and Sensor Networks: Architectures, Protocols, and Applications", src: ECE6, year: 2023, content: () => <p>Author :  Pulagam Hari Krishna, Venaktesh N<br/>Year :  2023<br/>ISBN No :  978-93-6738-832-7<br/>Cost :  ₹550 </p>},
  { title: "Digital Communication: Principles and Practices in a Connected World", src: ECE5, year: 2022, content: () => <p>Author :  Maria Irudaya Leo Joseph L, Sreedhar Kollem<br/>Year :  2022<br/>ISBN No :  978-93-6738-264-6<br/>Cost :  ₹550 </p>},
  { title: "Embedded Systems Design: Practical Approaches and Industry Insights", src: ECE4, year: 2022, content: () => <p>Author :  R Vijaya Prakash, Praveen Pappula<br/>Year :  2022<br/>ISBN No :  978-93-6738-265-3<br/>Cost :  ₹550 </p>},
  { title: "Modern VLSI Design: From Concept to Silicon", src: ECE3, year: 2022, content: () => <p>Author :  G. Vijaya, Usha Desai<br/>Year :  2022<br/>ISBN No :  978-93-6738-236-3<br/>Cost :  ₹550 </p>},
  { title: "Wireless Communication Systems: Design, Implementation, and Optimization", src: ECE2, year: 2022, content: () => <p>Author :  Ajayan J, Sandip Bhattacharya<br/>Year :  2022<br/>ISBN No :  978-93-6738-376-6<br/>Cost :  ₹550 </p>},
  { title: "Advanced Signal Processing Techniques: Theory and Applications", src: ECE1, year: 2022, content: () => <p>Author :  Ravichander Janapati, Malathy V<br/>Year :  2022<br/>ISBN No :  978-93-6738-584-5<br/>Cost :  ₹550 </p>},
  { title: "Probability and Statistics for Data Science A Comprehensive Introduction", src: M5, year: 2023, content: () => <p>Author :  Rajitha Gurijala, Pushpalatha Sarla<br/>Year :  2023<br/>ISBN No :  978-93-6738-355-1<br/>Cost :  ₹550 </p>},
  { title: "Linear Algebra in Action Concepts, Computation, and Connections", src: M4, year: 2023, content: () => <p>Author :  Kiran Kumar Thula, Laxman Raju Thoutam<br/>Year :  2023<br/>ISBN No :  978-93-6738-795-5<br/>Cost :  ₹550 </p>},
  { title: "Mathematical Modeling and Simulation Tools for Solving Real-World Problems", src: M3, year: 2022, content: () => <p>Author :  Raja Shekar P V Pushpalatha Sarla<br/>Year :  2022<br/>ISBN No :  978-93-6738-704-7<br/>Cost :  ₹550 </p>},
  { title: "Advanced Calculus Techniques and Applications for Engineers and Scientists", src: M2, year: 2022, content: () => <p>Author :  Rajitha Gurijala G Nagaraju<br/>Year :  2022<br/>ISBN No :  978-93-6738-613-2<br/>Cost :  ₹550 </p>},
  { title: "Foundations of Applied Mathematics Principles and Applications Across Disciplines", src: M1, year: 2022, content: () => <p>Author :  R Archana Reddy C Balarama Krishna<br/>Year :  2022<br/>ISBN No :  978-93-6738-519-7<br/>Cost :  ₹550 </p>},
  { title: "Cloud-Native Architectures: Building Scalable and Resilient Systems", src: CSE10, year: 2022, content: () => <p>Author :  Chakradhar Padamutham,Burra Vijay Kumar<br/>Year :  2022<br/>ISBN No :  978-93-6738-460-2<br/>Cost :  ₹550 </p>},
  { title: "Quantum Computing Algorithms: Bridging Theory and Practice", src: CSE1, year: 2022, content: () => <p>Author :  Subhajit Das, K Deepa<br/>Year :  2022<br/>ISBN No :  978-93-6738-178-6<br/>Cost :  ₹550 </p>},
  { title: "Autonomous Systems and Robotics: Algorithms and Engineering Practices", src: CSE9, year: 2023, content: () => <p>Author :  Eranki L N Kiran Kumar,Chakradhar Padamutham<br/>Year :  2023<br/>ISBN No :  978-93-6738-393-3<br/>Cost :  ₹550 </p>},
  { title: "Cybersecurity in the Age of IoT: Challenges and Solutions", src: CSE8, year: 2023, content: () => <p>Author :  G Nagaraju,Bivas Bank<br/>Year :  2023<br/>ISBN No :  978-93-6738-700-9<br/>Cost :  ₹550 </p>},
  { title: "Big Data Analytics: Scalable Techniques for Data-Driven Decision Making", src: CSE7, year: 2023, content: () => <p>Author :  Kiran Kumar Thula,Ravi Kiran Goolla<br/>Year :  2023<br/>ISBN No :  978-93-6738-174-8<br/>Cost :  ₹550 </p>},
  { title: "Natural Language Processing for Real-World Applications: A Practical Approach", src: CSE6, year: 2023, content: () => <p>Author :  Sharmila Banu N,Guduri Swamy Reddy<br/>Year :  2023<br/>ISBN No :  978-93-6738-205-9<br/>Cost :  ₹550 </p>},
  { title: "Secure Software Development: Principles, Practices, and Tools", src: CSE5, year: 2022, content: () => <p>Author :  Subhajit Das,Dasoju Sravan Kumar<br/>Year :  2022<br/>ISBN No :  978-93-6738-756-6<br/>Cost :  ₹550 </p>},
  { title: "Edge AI: Computational Intelligence on the Edge of Networks", src: CSE4, year: 2022, content: () => <p>Author :  Burra Vijay Kumar,K Sudheer Kumar<br/>Year :  2022<br/>ISBN No :  978-93-6738-055-0<br/>Cost :  ₹550 </p>},
  { title: "Blockchain Beyond Cryptocurrencies: Distributed Ledger Technologies in Action", src: CSE3, year: 2022, content: () => <p>Author :  Y Nagender,Pulagam Hari Krishna<br/>Year :  2022<br/>ISBN No :  978-93-6738-964-5<br/>Cost :  ₹550 </p>},
  { title: "Deep Learning Architectures: Innovations and Applications in AI", src: CSE2, year: 2022, content: () => <p>Author :  Kamalakar Ramineni,Ch Sandeep<br/>Year :  2022<br/>ISBN No :  978-93-6738-129-8<br/>Cost :  ₹550 </p>},
   
];

export default function BookCard() {
  const [active, setActive] = useState(null);
  const [selectedYear, setSelectedYear] = useState(String(2023)); // Initialize with current year
  // const [selectedYear, setSelectedYear] = useState(String(new Date().getFullYear())); // Initialize with current year
  const id = useId();
  const ref = useRef(null);

  const groupedCards = cards.reduce((acc, card) => {
    acc[card.year] = acc[card.year] || [];
    acc[card.year].push(card);
    return acc;
  }, {});

  // Sort years in descending order (latest year first)
  const sortedYears = Object.keys(groupedCards).sort((a, b) => b - a);

  useEffect(() => {
    function onKeyDown(event) {
      if (event.key === "Escape") {
        setActive(false);
      }
    }

    if (active && typeof active === "object") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [active]);

  useOutsideClick(ref, () => setActive(null));

  return (
    <>
      <AnimatePresence>
        {active && typeof active === "object" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/20 h-full w-full z-10"
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {active && typeof active === "object" ? (
          <div className="fixed inset-0 grid place-items-center z-[100] ">
            <motion.div
              layoutId={`card-${active.title}-${id}`}
              ref={ref}
              className="w-full max-w-[400px] h-full md:h-fit md:max-h-[100%] flex flex-col bg-neutral-900 sm:rounded-3xl overflow-hidden"
            >
              <motion.button
                key={`button-${active.title}-${id}`}
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, transition: { duration: 0.05 } }}
                className="flex absolute m-4 items-center justify-center rounded-full h-6 w-6"
                onClick={() => setActive(null)}
              >
                <p className="text-white bg-black w-10 rounded-full">X</p>
              </motion.button>
              <motion.div layoutId={`image-${active.title}-${id}`}>
                <img
                  src={active.src}
                  alt={active.title}
                  className="w-full h-80 lg:h-80 sm:rounded-tr-lg sm:rounded-tl-lg object-cover object-fill"
                  // style={{scale:'.75'}}
                />
              </motion.div>

              <div>
                <div className="flex justify-between items-start p-4">
                  <div>
                    <motion.h3
                      layoutId={`title-${active.title}-${id}`}
                      className="font-medium text-neutral- text-neutral-200 text-base"
                    >
                      {active.title}
                    </motion.h3>
                    <motion.p
                      layoutId={`description-${active.description}-${id}`}
                      className="text-neutral- text-neutral-400 text-base"
                    >
                      {active.description}
                    </motion.p>
                  </div>
                </div>
                <div className="pt-4 relative px-4">
                  <motion.div
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="text-xs md:text-sm lg:text-base h-40 md:h-fit pb-10 flex flex-col items-start gap-4 overflow-y-auto text-neutral-400"
                  >
                    {typeof active.content === "function"
                      ? active.content()
                      : active.content}
                  </motion.div>
                </div>
              </div>
            </motion.div>
          </div>
        ) : null}
      </AnimatePresence>

      <div className="max-w-[90%] mx-auto w-screen">
        <div className="flex justify-center gap-4 mb-4">
          {sortedYears.map((year) => (
            <button
              key={year}
              onClick={() => setSelectedYear(year)}
              className={`px-4 py-2 text-white rounded-lg ${
                selectedYear === year ? "bg-blue-500" : "bg-gray-700"
              }`}
            >
              {year}
            </button>
          ))}
        </div>

        {/* Render cards for selected year */}
        <ul className="grid grid-cols-1 md:grid-cols-3 items-start gap-4">
          {groupedCards[selectedYear]?.map((card) => (
            <motion.div
              layout
              key={card.title}
              className="p-4 bg-black hover:bg-neutral-900 rounded-xl cursor-pointer"
              onClick={() => setActive(card)}
            >
              <img
                src={card.src}
                alt={card.title}
                className="h-100 w-100 rounded-lg object-cover"
              />
              <div className="text-center mt-2" style={{height:'80px'}}>
                <h3 className="text-white">{card.title}</h3>
              </div>
            </motion.div>
          ))}
        </ul>
      </div>
    </>
  );
}
