import React from "react";
import {motion} from "framer-motion"
import BookCard from "../ui/bookcard";

const BookPublication = () => {
  return (
    <div className="min-h-screen overflow-auto pt-16 text-dark bg-{#874CCC} mt-[-3rem]" style={{backgroundColor:'#874CCC'}}>
      <div className="max-w-6xl mx-auto p-8">
        <motion.div
          className="space-y-4 mb-16"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl font-bold mb-4">YAR Tech Publisher</h1>
          <p className="text-lg mb-8">
            At <span className="font-weight-bold ">YAR Tech Publisher</span>, we are committed to advancing knowledge and
            empowering learners by delivering high-quality technical books.
            As a leading publisher in the fields of<span className="font-weight-bold">current technology,
            science, and mathematics</span> , we strive to provide readers with
            up-to-date, accurate, and engaging content that fosters a deeper
            understanding of the ever-evolving world of innovation.
          </p>
          <p className="text-lg mt-8">
          Our catalog spans various technical domains, including cutting-edge
           topics <span className="font-weight-bold">like artificial intelligence, data science, robotics, and
           quantum computing</span>, ensuring that both students and professionals
             have access to the latest advancements in these fields. We also
              specialize in fundamental sciences and mathematics, offering
               comprehensive resources that cater to both beginners and advanced learners.
          </p>
          <p className="text-lg mt-8">
          With a mission to bridge the gap between education and the real-world
           application of knowledge, YAR Tech Publisher partners with industry
            experts, researchers, and educators to create materials that are
             not only informative but also practical. Our books are designed
              to equip readers with the tools they need to succeed in the
               fast-paced world of technology and science.
          </p>
          <p className="text-lg mt-8">
          At YAR Tech Publisher, we believe in the transformative power of knowledge.
           Our goal is to be your trusted partner on the journey to mastery, providing
            the resources you need to excel in your academic and professional endeavors.
          </p>
       
        <div className="border-t border-1 border-white my-8"/>
        <BookCard />
        </motion.div>
      </div>
    </div>
  );
};

export default BookPublication;
